<template>
  <div class="merchantCenterList MERCH">
    <div class="merch-card">
      <div>
        <div class="firmBasics">供应商信息</div>
        <div class="supplier-mess">
          <div>
            <div><span>企业名称:</span>{{ businessInfo.name }}</div>
            <div>
              <span>企业注册地:</span>{{ businessInfo.province }}-{{
                businessInfo.city
              }}-{{ businessInfo.district }}
            </div>
          </div>
          <div>
            <div><span>简称:</span>{{ businessInfo.abbreviation }}</div>
            <div><span>企业类型:</span>{{ businessInfo.type | type }}</div>
          </div>
          <div>
            <div><span>法人:</span>{{ businessInfo.legalEntity }}</div>
            <div><span>联系电话:</span>{{ businessInfo.telephone }}</div>
          </div>
        </div>
      </div>
      <div class="mold">
        <div :class="mold == 1 ? 'active-mold' : ''" @click="chaMold(1)">
          经营范围对码
        </div>
        <div :class="mold == 2 ? 'active-mold' : ''" @click="chaMold(2)">
          会员类型对码
        </div>
        <div :class="mold == 3 ? 'active-mold' : ''" @click="chaMold(3)">
          会员资质对码
        </div>
      </div>
      <businessScope v-if="mold == 1" />
      <membershipType v-if="mold == 2" />
      <membershipQualification v-if="mold == 3" />
    </div>
    <!-- <merchantMessage /> -->
  </div>
</template>

<script>
import businessScope from "./ERP/businessScope.vue";
import membershipType from "./ERP/membershipType.vue";
import membershipQualification from "./ERP/membershipQualification.vue";
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  name: "changmerchantMessage",
  components: { businessScope, membershipType, membershipQualification },
  data() {
    return {
      mold: 1,
      businessInfo: {},
    };
  },
  filters: {
    type(v) {
      switch (v) {
        case 1:
          return "经营企业（批发）";
        case 3:
          return "经营企业（连锁）";
        case 2:
          return "生产企业";
      }
    },
  },
  created() {
    this.GetERPbusinessInfoApi();
  },
  methods: {
    ...merchantCenterList.mapActions(["GetERPbusinessInfo"]),
    async GetERPbusinessInfoApi() {
      let res = await this.GetERPbusinessInfo(this.$route.query.id);
      this.businessInfo = res.content;
      console.log(res);
    },
    chaMold(val) {
      this.mold = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.MERCH {
  height: 100%;
}
.firmBasics {
  font-size: 16px;
  font-weight: 700;
  height: 30px;
  // border-bottom: 1px dashed #ddd;
  display: flex;
  align-items: flex-end;
  color: #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.firmBasics::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
}
.supplier-mess {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #ddd;
  > div {
    color: #222;
    font-size: 14px;

    > div {
      margin-bottom: 30px;
    }
    span {
      color: #666;
      margin-right: 20px;
      font-size: 14px;
    }
  }
}
.mold {
  display: flex;
  margin-top: 27px;
  > div {
    width: 144px;
    height: 36px;
    background: #fff;
    border-radius: 3px 3px 3px 3px;
    line-height: 36px;
    text-align: center;
    color: #383b46;
    margin-right: 60px;
    cursor: pointer;
  }
  .active-mold {
    background-color: #f2f2f2;
    color: #06b7ae;
  }
}
</style>
<style  lang="scss">
.overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
