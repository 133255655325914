<template>
  <div>
    <div class="head">
      <div class="search-data">
        <div>
          <el-input
            maxlength="50"
            v-model="searchData.name"
            placeholder="ERP单位资质名称"
            clearable
            size="small"
            style="width: 250px"
          />
        </div>
        <div>
          <el-select
            v-model="searchData.typeId"
            placeholder="请选择平台会员资质"
            clearable
            size="small"
            style="width: 250px"
          >
            <el-option
              v-for="item in commType"
              :key="item.id"
              :label="item.type"
              :value="item.id"
            />
          </el-select>
        </div>
        <div>
          <el-select
            v-model="searchData.codeMatchStatus"
            placeholder="请选择状态"
            clearable
            size="small"
            style="width: 250px"
          >
            <el-option
              v-for="item in typeList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            />
          </el-select>
        </div>
        <div class="search-btn" @click="search()">查询</div>
      </div>
      <div class="right-btn">
        <!-- <div>同步ERP数据</div> -->

        <div>
          <el-upload
            :action="ERPimportQualification"
            :headers="{ TOKEN }"
            :show-file-list="false"
            :on-success="onUploadSuccess"
          >
            <span style="color: #fff">导入</span>
          </el-upload>
        </div>
        <div @click="template()">导入模板下载</div>
        <div class="no-border" @click="addData">新建</div>
      </div>
    </div>
    <el-table
      :data="list"
      :header-cell-style="{ background: '#F2F3F5', color: '#383B46' }"
      height="420"
      border
      style="width: 100%"
    >
      <el-table-column
        align="center"
        fixed="left"
        label="序号"
        type="id"
        width="70"
      >
        <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            :content="row.id + ''"
            placement="top"
          >
            <div class="exceed">{{ row.id }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="创建/同步时间" align="center">
        <template slot-scope="scope">
          <el-tooltip
            v-if="!scope.row.updateTime"
            class="item"
            effect="dark"
            :content="scope.row.createTime"
            placement="top"
          >
            <div>{{ scope.row.createTime }}</div>
            <!-- <div class="exceed">12312</div> -->
          </el-tooltip>
          <el-tooltip
            v-if="scope.row.updateTime"
            class="item"
            effect="dark"
            :content="scope.row.updateTime"
            placement="top"
          >
            <div>{{ scope.row.updateTime }}</div>
            <!-- <div class="exceed">12312</div> -->
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ERP单位资质名称" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.erpMatchingCode"
            placement="top"
          >
            <div class="overflow">{{ scope.row.erpMatchingCode }}</div>
            <!-- <div class="exceed">12312</div> -->
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="ERP单位资质ID" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.erpMatchingCodeId || '-'"
            placement="top"
          >
            <div>{{ scope.row.erpMatchingCodeId || "-" }}</div>
            <!-- <div class="exceed">12312</div> -->
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="平台会员资质" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.typeName"
            placement="top"
          >
            <div>{{ scope.row.typeName }}</div>
            <!-- <div class="exceed">12312</div> -->
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="备注" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.remark || '-'"
            placement="top"
          >
            <div>{{ scope.row.remark || "-" }}</div>
            <!-- <div class="exceed">12312</div> -->
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0" style="color: #f53f3f"
            >未对码</span
          >
          <span v-if="scope.row.status == 1" style="color: #383b46"
            >已对码</span
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="compileData(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="detailsing(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="paging">
      <div class="pages">
        共<span>{{ page.totalPage }}</span
        >页/<span>{{ page.total }}</span> 条数据
      </div>
      <!-- sizes,  -->
      <el-pagination
        background
        layout=" prev, pager, next, jumper"
        :page-size="page.pageSize"
        :total="page.total"
        :current-page.sync="searchData.currPage"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="dialogVisibleFormType ? '新建ERP单位资质' : '编辑ERP单位资质'"
      :visible.sync="dialogVisibleForm"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-form :rules="rules" :model="formName" ref="ruleForm">
        <el-form-item prop="erpMatchingCode" label="资质名称：">
          <el-input
            v-model="formName.erpMatchingCode"
            placeholder="资质名称(必填)"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item prop="erpMatchingCodeId" label="资质ID：">
          <el-input
            v-model="formName.erpMatchingCodeId"
            placeholder="请输入资质ID(选填)"
            maxlength="32"
          ></el-input>
        </el-form-item>
        <el-form-item prop="platformTypeId" label="平台会员资质：">
          <el-select
            v-model="formName.platformTypeId"
            placeholder="请选择平台会员资质"
            clearable
            size="small"
          >
            <el-option
              v-for="item in commType"
              :key="item.id"
              :label="item.type"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            v-model="formName.remark"
            placeholder="请输入备注(选填)"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">
          <span v-if="dialogVisibleFormType">新 建</span>
          <span v-if="!dialogVisibleFormType">保 存</span>
        </el-button>
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="删除ERP单位资质提醒"
      :visible.sync="dialogVisibleDel"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      ><span class="dialog-mess">
        确定要删除当前ERP单位资质？删除后可能会影响对应单位类型的平台会员，请谨慎操作。
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="affirmDel()"> 删 除 </el-button>
        <el-button @click="dialogVisibleDel = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="导入ERP单位资质提醒"
      :visible.sync="dialogVisibleRange"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="dialog-mess">
        共导入ERP单位资质 <span>{{ toLead.total }}</span> 张订单，成功
        <span>{{ toLead.successesCnt }}</span>
        张订单，导入成功后为已对码状态；导入失败
        <span>{{ toLead.errorCnt }}</span> 条。
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="errorLead" v-if="toLead.errorCnt > 0">
          下载导入失败模板
        </el-button>
        <el-button
          type="primary"
          @click="dialogVisibleRange = false"
          v-if="toLead.errorCnt == 0"
        >
          知道了
        </el-button>
        <!-- <el-button @click="dialogVisibleRange = false">知道了</el-button> -->
      </span>
    </el-dialog>
    <el-dialog
      title="ERP经营范围同步成功"
      :visible.sync="dialogVisibleSynchronization"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <span class="dialog-mess">
        本次同步新增 2
        条ERP经营范围，状态为未对码，请及时进行对码，避免出现异常。
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleSynchronization = false">
          知道了
        </el-button>
        <!-- <el-button @click="dialogVisibleRange = false">知道了</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
import { ERPimportQualification } from "@/api/javaUrl.js";
import { _params } from "babel-standalone";
// const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
import { downLoadZip } from "@/utils/zipdownload";
export default {
  name: "changmerchantMessage",
  data() {
    return {
      formName: {
        erpMatchingCode: "",
        erpMatchingCodeId: "",
        platformTypeId: "",
        remark: "",
      },
      rules: {
        erpMatchingCode: [
          {
            required: true,
            message: "单位资质名称不能为空。",
            trigger: "blur",
          },
        ],
        erpMatchingCodeId: [
          {
            pattern: "^[^\u4e00-\u9fa5]+$",
            message: "单位资质ID请输入32位以内的字母、数字、符号。",
          },
        ],
        platformTypeId: [
          {
            required: true,
            message: "请先选择平台会员资质。",
            trigger: "change",
          },
        ],
      },
      dialogVisibleFormType: false,
      dialogVisibleForm: false,
      searchData: {
        currPage: 1,
      },
      commType: [],
      typeList: [
        {
          type: 0,
          name: "未对码",
        },
        {
          type: 1,
          name: "已对码",
        },
      ],
      list: [],
      page: {
        pageSize: 10,
        total: 0,
      },
      dialogVisibleDel: false,
      ERPimportQualification: "",
      TOKEN: "",
      dialogVisibleRange: false, //导入ERP经营范围提醒弹窗
      dialogVisibleSynchronization: false, //ERP经营范围同步成功弹窗
      deleteId: null, //删除id
      toLead: {}, //导入完成
    };
  },
  computed: {
    // ...commonIndex.mapState(["BasissCopeData"]),
  },
  mounted() {
    this.ERPimportQualification = ERPimportQualification + this.$route.query.id;
    this.TOKEN = getToken();
  },
  created() {
    this.getCommType();
    this.getERPuserQualificationQueryApi();
  },

  methods: {
    ...merchantCenterList.mapActions([
      "GetERPgetAllUserQualification",
      "getERPuserQualificationQuery",
      "postERPcreate",
      "postERPupdate",
      "GetERPdelete",
      "GetERPexportScopeTemplate",
    ]),
    errorLead() {
      let queryParams = null;
      downLoadZip(
        `/ptuser//erpCodeMatch/exportQualificationErrorData/` +
          this.$route.query.id,
        "xlsx",
        "会员资质导入失败模板",
        queryParams,
        "app",
        "get"
      );
      this.dialogVisibleRange = false;
    },
    // 导入
    onUploadSuccess(response, file, fileList) {
      // 处理上传成功返回的结果

      if (response.code == "000000") {
        this.toLead = response.content;
        this.dialogVisibleRange = true;
        this.getERPuserQualificationQueryApi();
      } else {
        this.$message({
          message: response.message,
          type: "warning",
        });
      }
    },
    // 模板下载
    async template() {
      let queryParams = null;
      downLoadZip(
        `/ptuser/erpCodeMatch/exportQualificationTemplate`,
        "xlsx",
        "会员资质导入模板",
        queryParams,
        "app",
        "get"
      );
    },
    search() {
      this.searchData.currPage = 1;
      this.getERPuserQualificationQueryApi();
    },

    // 经营范围对码
    async getERPuserQualificationQueryApi() {
      let params = this.searchData;
      params.businessId = this.$route.query.id;
      let res = await this.getERPuserQualificationQuery(params);
      if (res.code == "000000") {
        if (params.currPage > res.content.totalPage && params.currPage != 1) {
          this.searchData.currPage = this.searchData.currPage - 1;
          this.getERPuserQualificationQueryApi();
          return;
        }
        // this.$publicFunction.timestamp(1683510416000)
        res.content.list.forEach((item) => {
          item.createTime = this.$publicFunction.timestamp(item.createTime);
          if (item.updateTime) {
            item.updateTime = this.$publicFunction.timestamp(item.updateTime);
          }
        });
        // res.console
        this.list = res.content.list;
        this.page.total = res.content.totalRecords;
        this.page.totalPage = res.content.totalPage;
      }
    },
    // 打开删除弹框
    detailsing(row) {
      this.dialogVisibleDel = true;
      // console.log(row)
      this.deleteId = row.id;
    },
    // 确认删除
    async affirmDel() {
      let res = await this.GetERPdelete(this.deleteId);
      if (res.code == "000000") {
        this.getERPuserQualificationQueryApi();
        // this.deleteId = null;
        this.dialogVisibleDel = false;
        this.$message({
          message: "ERP单位资质删除成功",
          type: "success",
        });
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    async submitFormApi(val) {
      let res = null;
      if (this.dialogVisibleFormType) {
        // 平台ERP对码信息创建
        res = await this.postERPcreate(this.formName);
      } else {
        // 平台ERP对码信息修改
        res = await this.postERPupdate(this.formName);
      }
      if (res.code == "000000") {
        this.getERPuserQualificationQueryApi();
        this.formName.id = null;
        this.resetForm(val);
        this.dialogVisibleForm = false;
        if (this.dialogVisibleFormType) {
          this.$message({
            message: "新建ERP单位资质成功。",
            type: "success",
          });
        } else {
          this.$message({
            message: "ERP单位资质信息编辑成功。",
            type: "success",
          });
        }
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    // 确认提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formName.businessId = this.$route.query.id;
          this.formName.platformType = 3;
          this.submitFormApi(formName);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },

    // 取消表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.formName.remark = "";
      this.dialogVisibleForm = false;
    },
    // 获取平台经营范围
    async getCommType() {
      let data = await this.GetERPgetAllUserQualification();
      this.commType = data.content;
    },
    handleCurrentChange(val) {
      this.searchData.currPage = val;
      this.getERPuserQualificationQueryApi();
    },
    // 编辑
    compileData(val) {
      this.dialogVisibleForm = true;
      this.dialogVisibleFormType = false;
      this.formName = {
        erpMatchingCode: val.erpMatchingCode,
        erpMatchingCodeId: val.erpMatchingCodeId,
        platformTypeId: val.platformTypeId,
        remark: val.remark,
        id: val.id,
      };
    },
    // 新建
    addData() {
      this.dialogVisibleForm = true;
      this.dialogVisibleFormType = true;
      this.formName = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  justify-content: space-between;
  .search-data {
    display: flex;
    > div {
      margin-right: 20px;
    }
    .search-btn {
      height: 32px;
      line-height: 32px;
      width: 52px;
      background: #06b7ae;
      border-radius: 3px 3px 3px 3px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .right-btn {
    height: 32px;
    background: #06b7ae;
    border-radius: 3px 3px 3px 3px;
    padding: 6px 0;
    font-size: 14px;
    color: #fff;
    display: flex;
    > div {
      line-height: 20px;
      padding: 0 12px;
      cursor: pointer;
      border-right: 1px solid #fff;
    }
    .no-border {
      border-right: none;
    }
  }
}
.paging {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
::v-deep .el-form-item {
  display: flex;
}
::v-deep .el-form-item__label {
  width: 140px;
}
::v-deep .el-form-item__content {
  flex: 1;
  .el-select {
    width: 100%;
    .el-input__inner {
      height: 40px;
      line-height: 40px;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
.dialog-mess {
  line-height: 24px;
  span {
    color: #06b7ae;
  }
}
.pages {
  line-height: 32px;
  font-size: 14px;
  color: #31373d;
  span {
    color: #06b7ae;
  }
}
</style>
